<template>
  <div class="featured">

    <div class="last-order-container">
      <h4 class="featured-title">Last order</h4>
      <div class="last-order" v-if="latestOrder">
        <div class="header">
          <div class="image">
            <i class='bx bx-receipt'></i>
          </div>
          <div class="customer">
            <div class="name-date">
              <div class="name">{{latestOrder.Data.firstLastName}}</div>
              <div class="date">{{new Date(latestOrder.Timestamp).toLocaleString()}}</div>

            </div>
            <div class="other-details">
              <div class="email">{{latestOrder.Data.email}}</div>
              <div class="country">{{latestOrder.Data.country}}</div>
            </div>
          </div>
        </div>
        <h5 class="parts-title">Parts</h5>
        <div class="parts">
          <div class="part" v-for="item, i in latestOrderParts" v-bind:key="i">
            <img :src="typeIcon(item.Type)" alt="" class="part-icon">
            <div class="thumbnail">
              <img :src="`/assets/${item.Items.thumbnail}`" />
            </div>
            <div class="part-name">{{item.Name}}<span class="part-manufacturer"> by {{item.Manufacturer}}</span></div>
          </div>

        </div>
        <div class="actions">
            <vs-button
            flat
            success
            >
            <i class='bx bx-check'></i> <span class="button-icon-text"> Confirm</span>
          </vs-button>
          <vs-button
            flat
            danger
            >
            <i class='bx bx-trash' ></i> <span class="button-icon-text">Delete</span>
          </vs-button>
        </div>
      </div>

    </div>
    <div class="basic-analytics-container">
      <h4 class="featured-title">Last week overview</h4>
      <div class="basic-analytics">
        <doughnut-chart :styles="{height: '250px', position: 'relative'}" :data="[50, 12, 6]" :labels="['Started', 'Picked parts', 'Ordered']"></doughnut-chart>
        <div class="basic-analytics-example">EXAMPLE</div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from './DoughnutChart.vue'
export default {
  props: ['latestOrder'],
  components: { DoughnutChart },

  computed: {
    latestOrderParts: function () {
      if (this.latestOrder) {
        return Object.keys(this.latestOrder.Data.parts).map(a => this.latestOrder.Data.parts[a])
      }
      return []
    }
  },
  methods: {
    typeName: function (t) {
      switch (t) {
        case 'case': return 'Case'
        case 'bezel': return 'Bezel'
        case 'hands': return 'Hands'
        case 'dial': return 'Dial'
        default: return ''
      }
    },
    typeIcon: function (t) {
      switch (t) {
        case 'case': return require('../../assets/admin/parts/caseVector.svg')
        case 'bezel': return require('../../assets/admin/parts/bezelVector.svg')
        case 'dial': return require('../../assets/admin/parts/dialVector.svg')
        case 'hands': return require('../../assets/admin/parts/handsVector.svg')
        default: return require('../../assets/admin/parts/caseVector.svg')
      }
    }
  }
}
</script>

<style scoped>
.basic-analytics-container{
  display: flex;
  flex-direction: column;
  align-self: stretch;

}
.actions{
  display: flex;
  justify-content: flex-end;
}
.button-icon-text{
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 600;
  margin-left: 5px;
}
.basic-analytics-example{
  position: absolute;
  top: 17px;
  left: -40px;
  transform: rotate(-45deg);
  background-color: rgba(var(--vs-danger),1);
  color: white;
  padding: 5px 40px;
}
.part-icon{
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 50px;
  width: 50px;
  z-index: 0;
  opacity: 0.2;
}
.part-manufacturer{
  font-weight: 400;
  font-size: 14px;
}
.part-name{
  margin-left: 10px;
  font-weight: 600;
  font-size: 17px;
}
.parts{
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.part{
  position: relative;
  height: 60px;
  width: calc(50% - 10px);
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  display: flex;
  margin: 5px;
  align-items: center;
  transition: background-color 0.25s ease;
}
.part:hover{
  background-color: rgba(0, 0, 0, 0.05);
}
.thumbnail{
  height: 40px;
  width: 40px;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 10px;
}
.thumbnail img {
  height: 100%;
  width: 100%;
}

.featured {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
.featured-title{
  margin:10px 0px 10px 20px;
}
.last-order-container{
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

}
.last-order{
  flex: 1 1 0;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,var(--vs-shadow-opacity));
  margin-right: 25px;
  padding: 20px;
  border-radius: 30px;
  background-color: white;
}
.header{
  display: flex;
}
.image{
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  background-color: rgb(36, 33, 69);
  border-radius: 20px;
  color:white;
}
.customer{
  height: 60px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  margin-left: 10px;
  flex: 1 1 0;
  transition: background-color 0.25s ease;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}
.customer:hover{
  background-color: rgba(0, 0, 0, 0.05);
}
.name-date{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name{
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.date{
  margin-right: 10px;
  margin-left: 10px;
  margin-top: -3px;
  color: rgb(180,180,180);
  font-size: 13px;
}
.other-details{
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  justify-content: center;
}
.parts-title{
  margin-top:20px;
  margin-bottom: 5px;
}
.basic-analytics{
  flex: 1 0 250px;
  position: relative;
  min-width: 350px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,var(--vs-shadow-opacity));
  margin-left: 25px;
  padding: 20px;
  border-radius: 30px;
  overflow: hidden;
  background-color: white;
}
</style>
