<template>
  <div class="container" v-show="!loading">
    <div class="left-side">
      <div class="b">
        <div class="night-overlay" :class="{visible:nightMode}">
          <div class="builder-container">
            <div class="builder">
              <div class="case">
                <img class="case-image" :src="getImgUrl(`parts/case/placeholder.png`)" />
                <div class="dial">

                  <!--<img class="dial-image"    :src="getImgUrl(`parts/dial/${selectedParts.previousDial}.png`)" />-->
                  <img v-if="dialNightImage" class="dial-image night" :src="dialNightImage" />

                </div>

                <div class="hands">
                  <img class="hour-hand-image night-img" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.hour}deg)`" :src="hourHandImage" />
                  <img class="hour-hand-image night-img-overlay" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.hour}deg)`" :src="hourHandNightImage" />

                  <img class="minute-hand-image night-img" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.minute}deg)`" :src="minuteHandImage" />
                  <img class="minute-hand-image night-img-overlay" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.minute}deg)`" :src="minuteHandNightImage" />
                  <img class="second-hand-image night-img" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.second}deg)`"  :src="secondHandImage" />

                </div>

              </div>
            </div>
          </div>
        </div>
          <div class="builder-container">
            <div class="builder">
              <div class="case">
                <img class="case-image" :src="selectedParts.case.Items.case ? `/assets/${selectedParts.case.Items.case}` : null" />
                <div class="movement">
                  <img class="movement-image" src="../assets/parts/movement/nh36.png" />
                </div>

                <div class="dial">
                  <!--<img class="dial-image-back" :class="{slice: activePartTransition.dial}" :src="getImgUrl(`parts/dial/${activePartTransition.dial ?   selectedParts.dial : selectedParts.previousdial}.png`)"/>-->
                  <img class="dial-image" :src="dialImage" />

                </div>
                <div class="hands">
                  <img class="hour-hand-image" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.hour}deg)`" :src="hourHandImage" />
                  <img class="minute-hand-image" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.minute}deg)`" :src="minuteHandImage" />
                  <img class="second-hand-image" :class="{'hand-quick-transition':rotationSet}" :style="`transform: translate(-50%,-70%) rotate(${timeRotation.second}deg)`"  :src="secondHandImage" />

                </div>

                <div class="bezel">
                  <img class="bezel-image" :src="selectedParts.bezel.Items.bezel ? `/assets/${selectedParts.bezel.Items.bezel}`: null" />
                </div>
              </div>
              <div class="center"></div>
            </div>
          </div>
      </div>
      <div class="b2">
        <mobile-controls v-show="!done" @changePart="changePart" @changeNightMode="changeNightMode" :currentNightMode="nightMode"  @done="orderDone" :parts="parts" :selectedParts="selectedParts"></mobile-controls>
        <div v-show="done" class="thankyou-container">
        <div class="thankyou-content">
          <h2 class="not-margin order-header-title">
            <span >You are done!</span>

          </h2>
          <h4 class="not-margin order-header-subtitle">
            <span> Inquiry has been sent, now kick back and relax and we will contact you as soon possible with all the details about your dream watch.</span>
          </h4>
          <vs-button
            size="large"
            block
            flat
            @click="done = false"
            >
            <i style="margin-rigth:5px" class='bx bx-left-arrow-alt'></i> Back
          </vs-button>
        </div>
      </div>
      </div>
    </div>
    <div class="right-side">
      <controls v-show="!done" @changePart="changePart" @changeNightMode="changeNightMode" @done="orderDone" :parts="parts" :currentNightMode="nightMode" :selectedParts="selectedParts"></controls>
      <div v-show="done" class="thankyou-container">
        <div class="thankyou-content">
          <h2 class="not-margin order-header-title">
            <span >You are done!</span>

          </h2>
          <h4 class="not-margin order-header-subtitle">
            <span> Inquiry has been sent, now kick back and relax and we will contact you as soon possible with all the details about your dream watch.</span>
          </h4>
          <vs-button
            size="large"
            block
            flat
            @click="done = false"
            >
            <i style="margin-rigth:5px" class='bx bx-left-arrow-alt'></i> Back
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as Api from '@/api'
import Controls from '@/components/builder/Controls'
import MobileControls from '../components/builder/MobileControls.vue'

export default {
  components: {
    controls: Controls,
    MobileControls
  },
  data: function () {
    return {
      done: false,
      dials: ['blue', 'starsky'],
      cases: ['metalCase', 'roseGoldCase'],
      bezels: ['metalBezel', 'roseGoldBezel'],
      dialIndex: 0,
      prevDialIndex: 1,
      caseIndex: 0,
      bezelIndex: 0,
      active: 1,
      slice: false,
      loading: true,
      parts: {
        case: [],
        bezel: [],
        hands: [],
        dial: []
      },
      selectedParts: {
        case: { ID: null, Items: {} },
        bezel: { ID: null, Items: {} },
        dial: { ID: null, Items: {} },
        hands: { ID: null, Items: {} }
      },
      partTransition: {
        dial: true
      },
      activePartTransition: {
        dial: true // true means 0
      },
      timeRotation: {
        hour: 0,
        minute: 0,
        second: 0
      },
      rotationSet: false,
      nightMode: false,
      okToLoad: false
    }
  },
  computed: {
    getSelectedDial: function () {
      const dial = this.parts.dials.find(a => a.name === this.selectedParts.dial)
      if (dial) {
        return dial
      }
      return {}
    },
    hourHandImage () {
      return this.selectedParts.hands.Items.handHour ? `/assets/${this.selectedParts.hands.Items.handHour}` : null
    },
    minuteHandImage () {
      return this.selectedParts.hands.Items.handMinute ? `/assets/${this.selectedParts.hands.Items.handMinute}` : null
    },
    secondHandImage () {
      return this.selectedParts.hands.Items.handSecond ? `/assets/${this.selectedParts.hands.Items.handSecond}` : null
    },
    hourHandNightImage () {
      return this.selectedParts.hands.Items.handHourNight ? `/assets/${this.selectedParts.hands.Items.handHourNight}` : null
    },
    minuteHandNightImage () {
      return this.selectedParts.hands.Items.handMinuteNight ? `/assets/${this.selectedParts.hands.Items.handMinuteNight}` : null
    },
    secondHandNightImage () {
      return this.selectedParts.hands.Items.handSecondNight ? `/assets/${this.selectedParts.hands.Items.handSecondNight}` : null
    },
    dialImage () {
      return this.selectedParts.dial.Items.dial ? `/assets/${this.selectedParts.dial.Items.dial}` : null
    },
    dialNightImage () {
      return this.selectedParts.dial.Items.dialNight ? `/assets/${this.selectedParts.dial.Items.dialNight}` : null
    }
  },
  methods: {
    getImgUrl (pic) {
      return require('../assets/' + pic)
    },
    changePart (type, part) {
      Api.trackEvent({ id: 'builder-part-change', data: { type, part } })
      this.$gtag.event('builder-part-change', { data: { type, part } })

      if (this.selectedParts[type] === part) return

      this.selectedParts[type] = part
      this.slice = !this.slice
      this.partTransition[type] = false
      this.activePartTransition[type] = !this.activePartTransition[type]
    },
    setRotations (upfront = false) {
      const time = new Date()
      let totalMilisecond = time.getMilliseconds() + time.getSeconds() * 1000
      if (upfront) {
        totalMilisecond += 1000
      }
      this.timeRotation.second = totalMilisecond / 60000.0 * 360.0

      const totalSeconds = time.getSeconds() + time.getMinutes() * 60
      this.timeRotation.minute = totalSeconds / 3600 * 360.0

      const totalMinutes = time.getMinutes() + time.getHours() * 60
      this.timeRotation.hour = totalMinutes / 720 * 360.0
    },
    changeNightMode (nightMode) {
      this.nightMode = nightMode
    },
    orderDone () {
      this.done = true
    }
  },
  mounted: function () {
    if (!this.okToLoad) return
    this.loading = true
    const loading = this.$vs.loading({
      type: 'corners',
      text: 'Loading...'
    })
    console.log(loading)
    Api.trackEvent({ id: 'builder-loaded' })
    this.$gtag.event('builder-loaded', { })

    Api.getParts(this.$route.params.seller)
      .then(parts => {
        if (parts.length === 0) {
          this.loading = false
          loading.close()
          return this.$router.push('/')
        }
        parts.forEach(part => {
          this.parts[part.Type].push(part)
        })

        this.selectedParts.case = this.parts.case[0]
        this.selectedParts.bezel = this.parts.bezel[0]
        this.selectedParts.hands = this.parts.hands[0]
        this.selectedParts.dial = this.parts.dial[0]
        setTimeout(() => {
          loading.close()
          this.loading = false
        }, 1000)
      })
    setTimeout(() => {
      this.setRotations(true)
      this.rotationSet = true

      setInterval(() => {
        this.setRotations(false)
      }, 200)
    }, 1000)
  },
  created: function () {
    if (!this.$route.params.seller) {
      return this.$router.replace('/')
    }
    this.okToLoad = true
    document.title = `Combinative | ${this.$route.params.seller}`
  }
}
</script>

<style>
  .thankyou-container{
    text-align: center;
    padding: 50px;
    flex: 1 0 0;
    display: flex;
    justify-content: center;
  }
  .thankyou-content{
    max-width: 600px;
  }
  .container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .left-side{
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 100%;
  }
  .right-side {
    flex-grow: 8;
  }
  .night-toggle{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 20;
  }
  .b2{
      display:none;
      flex-grow: 1;
      height: 60vh;
  }
  .b {
    max-width: 750px;
    height: 100vh;
    background-image: url(../assets/bg_small.jpg);
    display:flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0px 30px 10px rgba(0,0,0,0.9) inset;
    position: relative;
    background-position: center;
    image-rendering: pixelated;
    overflow: hidden;
  }
  .top-bar{
    height: 20px;
    display: flex;
  }
  .night-overlay{
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 1s;
    image-rendering: auto;
  }
  .day-overlay{
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    image-rendering: auto;
  }
  .night-overlay.visible{
    opacity: 1;
  }
  .night-img {
    filter: brightness(30%);
  }

  .builder-container{
    width: 300px;
    height: 300px;
    display: flex;
    border-radius: 20px;
    padding: 50px;
    background-size: 100%;
    position: relative;
    image-rendering: auto;
  }
  .builder {
    height: 100%;
    width:100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .center{
    position: absolute;
    height:10px;
    width: 10px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  .case {
    display: flex;
    position: relative;
    height:100%;
    width:100%;

    filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.9));
  }
  .case-image {
    align-self: center;
    height: 100%;
    width: 100%;
  }
  .case-image-placeholder {
    align-self: center;
    height: 300px;
    width: 300px;
    background-color: red;
  }
  .movement{
    height: 65%;
    width: 65%;
    position: absolute;
    transform: translate(-50%,-50%) rotate(1deg);
    top:50%;
    left: 50%;
  }
  .movement-image{
    align-self: center;
    height: 100%;
    width: 100%;
  }
  .dial {
    height: 62%;
    width:62%;
    position: absolute;
    transform: translate(-50%,-50%) ;
    top:50%;
    left: 50%;

  }
  .dial-image{
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    clip-path: polygon(0 0, 0 200%, 200% 0);
    transition: clip-path 1s;
    position: absolute;
  }
  .dial-image-back{
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
    transition: clip-path 1s;
    position: absolute;

  }
  .night{
    position: absolute;
  }
  .dial-image.slice {
    clip-path: polygon(0 0, 0 0, 0 0);
  }
  .dial-image-back.slice {
    clip-path: polygon(100% 100%, -100% 100%, 100% -100%);
  }
  .hands {
    position: absolute;
    height: 100%;
    width: 100%;
    top:50%;
    left: 50%;
    transform-origin: top left;
    transform: scale(0.50);
  }
  .bezel {
    height: 90%;
    width: 90%;
    position: absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left: 50%;
  }
  .bezel-image{
    height: 100%;
    width: 100%;
    position: relative;
  }

  .hour-hand-image{
    position: absolute;
    height: 100%;
    transform-origin: 50% 70%;
    transform: translate(-50%,-70%) rotate(45deg);
    transition: transform 1000ms;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.9))

  }
  .hour-hand-image.night-img{
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.9)) brightness(30%);
  }

  .minute-hand-image{
    position: absolute;
    height: 100%;
    transform-origin: 50% 70%;
    transform: translate(-50%,-70%) rotate(93deg);
    transition: transform 1000ms;
    filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.5))

  }
  .minute-hand-image.night-img{
    filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.5)) brightness(30%);
  }

  .second-hand-image{
    position: absolute;
    height: 100%;
    transform-origin: 50% 70%;
    transform: translate(-50%,-70%) rotate(270deg);
    transition: transform 1000ms;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.9))

  }
  .second-hand-image.night-img{
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.9)) brightness(30%);
  }
  .hand-quick-transition{
    transition: transform 5ms;
  }

  @media only screen and (max-width: 750px) {
    .right-side{
      display:none;
    }
    .b{
      height: 40vh;
    }
    .b2{
      display: flex;
    }
    .builder-container {
      max-width: 300px;
      max-height: 300px;
      height: auto;
      width: auto;
      flex-grow: 1;
    }
  }
</style>
