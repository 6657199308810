<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['data', 'labels'],
  mounted () {
    this.renderChart({
      datasets: [{
        data: this.data,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ]
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: this.labels
    }, { responsive: true, maintainAspectRatio: false })
  }
}
</script>
