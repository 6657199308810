<template>
  <div class="part-container" :class="{mobile}">
    <vs-tooltip v-if="!mobile"  class="part-tooltip" left shadow>
      <part  @changePart="changePart" :title="title" :description="description" :items="items" :selectedItem="selectedItem" :type="type" :mobile="mobile"></part>
      <template #tooltip>
      <div class="content-tooltip">
          <p> {{description}}</p>
      </div>
      </template>
    </vs-tooltip>
    <div v-else>
      <part @changePart="changePart" :title="title" :description="description" :items="items" :selectedItem="selectedItem" :type="type" :mobile="mobile"></part>
    </div>
  </div>
</template>

<script>
import Part from './Part.vue'
export default {
  components: { Part },
  props: ['title', 'description', 'items', 'selectedItem', 'type', 'mobile'],
  methods: {
    getImgUrl (pic) {
      return require('@/assets/' + pic)
    },
    changePart (type, selectedPart) {
      this.$emit('changePart', type, selectedPart)
    }
  }
}
</script>
<style scoped>
.part-container{
  margin-bottom: 30px;
}
.mobile{
  margin-bottom: 15px;
}
.part{

  display: flex;
  justify-content: stretch;
  position: relative;
}

.part-tooltip{
  display: flex;
}

</style>
