<template>
  <div class="container">
    <div class="top-controls">
      <div class="night-mode-controls">
        <h5 class="night-mode-title">Night mode</h5>
        <vs-switch dark v-model="nightMode">
          <template #circle>
              <i v-if="!nightMode" class='bx bxs-sun' ></i>
              <i v-else class='bx bxs-moon' ></i>
          </template>
        </vs-switch>
      </div>
    </div>
    <div class="main-controls">
      <div class="controls">
      <h1>Configure your watch</h1>
      <h3 class="subtitle">Pick colors, parts and designs that match your life style and experience quality watches made <span style="font-weight:600">custom</span> just for you.</h3>
        <part-container
          title="CASE"
          description="Case is main component holding and protecting all other parts. It is usually metalic but it can come in different materials and colors"
          :items="parts.case"
          :selectedItem="selectedParts.case"
          type="case"
          @changePart="changePart"
        ></part-container>
        <part-container
          title="BEZEL"
          description="Bezel is top cover on case and it containes bazel insert, it can be rotate one way or both way"
          :items="parts.bezel"
          :selectedItem="selectedParts.bezel"
          type="bezel"
          @changePart="changePart"
        ></part-container>
        <part-container
          title="DIAL"
          description="Dial is positioned on top of the movement and it is portion of wathc that display time"
          :items="parts.dial"
          :selectedItem="selectedParts.dial"
          type="dial"
          @changePart="changePart"
        ></part-container>
        <part-container
          title="HANDS"
          description="Hands are the markers on the dial that indicate time. Most watches have at least three hands to show hours, minutes, and seconds"
          :items="parts.hands"
          :selectedItem="selectedParts.hands"
          type="hands"
          @changePart="changePart"
        ></part-container>
        <!--

        <div class="part">
          <div class="part-left-shadow-drop"></div>
          <div class="part-right-shadow-drop"></div>
          <div class="part-title">HANDS</div>
          <div class="items">
            <div class="item-container" v-for="(item, index) in parts.hands" v-bind:key="index">
                <div class="item" :class="{selected:selectedParts.hands == item.name}" v-on:click="changePart('hands', item.name)">
                  <img class="item-thumbnail" :src="getImgUrl(`parts/hands/thumbnail/${item.name}.png`)" />
                </div>
            </div>

          </div>
        </div>-->
      </div>

      <div class="bottom-controls">
        <vs-button
        size="large"
        block
        flat
        @click="openOrderDialog"
        >
        Continue <i style="margin-left:5px" class='bx bx-right-arrow-alt'></i>
      </vs-button>
      </div>
    </div>
    <div class="footer">
      <a target="_blank" href="/" >Powered by <img src="../../assets/icon.svg" /> <span style="font-weight:600">COMBINATIVE</span></a>
    </div>
    <order-dialog @done="orderDone" :selectedParts="selectedParts" ref="orderDialog"></order-dialog>
  </div>
</template>

<script>
import * as Api from '@/api'

import PartContainer from '@/components/builder/PartContainer'
import OrderDialog from './OrderDialog.vue'

export default {
  components: {
    PartContainer,
    OrderDialog
  },
  props: ['parts', 'selectedParts', 'currentNightMode'],
  data: function () {
    return {
      orderDialog: false,
      nightMode: false,
      orderData: {
        firstLastName: '',
        email: '',
        country: '',
        build: true,
        done: false
      }
    }
  },
  methods: {
    getImgUrl (pic) {
      return require('@/assets/' + pic)
    },
    changePart (type, selectedPart) {
      this.$emit('changePart', type, selectedPart)
    },
    openOrderDialog () {
      Api.trackEvent({ id: 'builder-start-order', data: this.selectedParts })
      this.$gtag.event('builder-start-order', { data: this.selectedParts })
      this.$refs.orderDialog.showDialog()
    },
    orderSend () {
      const loading = this.$vs.loading({
        type: 'corners',
        text: 'Sending inquiry to our robots...'
      })
      setTimeout(() => {
        loading.close()
        this.orderData.done = true
      }, 3000)
    },
    orderDone: function () {
      this.orderDialog = false
      this.$refs.orderDialog.hideDialog()
      this.$emit('done')
    }
  },
  watch: {
    nightMode: function (newValue) {
      this.$emit('changeNightMode', newValue)
    },
    currentNightMode: function (newValue) {
      this.nightMode = newValue
    }
  }
}
</script>

<style scoped>

.container{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 100vh;
  position: relative;
}
.top-controls{
  position: absolute;
  right:0px;
  top:0px;
  padding-right:20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center ;
}
.night-mode-controls{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.night-mode-controls:hover > .night-mode-title{
  width: 80px;
}

.night-mode-title{
  width: 0px;
  overflow: hidden;
  white-space:nowrap;
  margin-right:10px;
  transition: width 500ms;
}
.main-controls{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 50px;
  max-width: 600px;
}
.controls{
}
.subtitle{
font-weight: 400;
margin-bottom: 30px;
}

.bottom-controls{
  align-self: flex-end;
}

.footer {
  align-self: flex-end;
  margin: 10px;

}
.footer img {
  margin-left: 5px;
  height: 20px;
  width: 20px;
}
.footer a{
  text-decoration: unset;
  color: #2c3e50;
    display: flex;
  align-items: center;
}

</style>
