<template>
  <div class="home-container">
    <h2>Home</h2>
    <home-featured :latestOrder="latestOrder"> </home-featured>
    <h4 class="orders-title">All orders</h4>
    <div class="orders">
      <home-order v-for="order, i in fitlteredOrders" v-bind:key="i" :order="order"></home-order>
    </div>
  </div>
</template>

<script>
import * as Api from '@/api'
import HomeFeatured from './HomeFeatured.vue'
import HomeOrder from './HomeOrder.vue'
export default {
  components: { HomeFeatured, HomeOrder },
  data: function () {
    return {
      orders: [],
      latestOrder: null,
      hidden: false
    }
  },
  methods: {

  },
  computed: {
    fitlteredOrders: function () {
      return this.orders.filter(o => o !== this.latestOrder)
    }
  },
  created: function () {
    document.title = 'Admin | Home'
    Api.getOrders()
      .then(response => {
        this.orders = response
        let newest = new Date(0)
        let latestOrder = null
        this.orders.forEach(o => {
          if (new Date(o.Timestamp) > newest) {
            newest = new Date(o.Timestamp)
            latestOrder = o
          }
        })

        this.latestOrder = latestOrder
      })
  }
}
</script>

<style scoped>
.home-container{
  width: 100%;
}
.orders-title{
  margin:20px 0px -10px 20px;
}
.orders{
  margin-top: 20px;
}

</style>
