<template>
  <div class="analytics-container">
    <h2>Analytics</h2>
    <div class="charts">
      <div class="bar-chart">
        <bar-chart :styles="{height: '250px', position: 'relative'}"></bar-chart>
      </div>
      <div class="doughnut-charts">
        <div class="dougnut-chart">
          <doughnut-chart :styles="{height: '250px', position: 'relative'}" :data="[1, 12, 6]" :labels="['Started', 'Picked parts', 'Ordered']"></doughnut-chart>
        </div>
        <div class="dougnut-chart">
          <doughnut-chart :styles="{height: '250px', position: 'relative'}" :data="[50, 6, 6]" :labels="['Started', 'Picked parts', 'Ordered']"></doughnut-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart.vue'
import DoughnutChart from './DoughnutChart.vue'
export default {
  components: { BarChart, DoughnutChart },
  created: function () {
    document.title = 'Admin | Analytics'
  }
}
</script>

<style scoped>
.analytics-container{
  width: 100%;
}
.bar-chart{
  height: 250px;
}
.doughnut-charts{
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
.charts{
  filter: blur(5px);
  cursor: default;
}
</style>
