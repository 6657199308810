<template>
   <div class="part-item" >

    <div class="part-item-thumbnail">

      <img :src="`/assets/${item.Items.thumbnail}`" />
    </div>
    <div class="part-item-content">
      <div class="part-item-info">
        <div class="part-item-info-name">{{item.Name}}</div>
        <div class="part-item-info-manufacturer">{{item.Manufacturer}}</div>
      </div>
      <div class="part-item-actions">
        <vs-button
          v-if="!item.Permanent"
          icon
          flat
          @click="editPart"
        >
          <i class='bx bx-edit' ></i>
        </vs-button>

        <vs-tooltip bottom shadow not-hover v-model="deleteDialog" v-if="!item.Permanent">
          <vs-button
          icon
          color="danger"
          flat
          @click="deleteDialog = !deleteDialog"
        >
          <i class='bx bx-trash' ></i>
        </vs-button>
          <template #tooltip>
            <div class="content-tooltip">
              <p>
                You are sure to delete this watch part, by doing so you cannot recover the data
              </p>
              <footer>
                <div class="delete-footer">
                  <vs-button @click="deletePart" danger block>
                    Delete
                  </vs-button>
                  <vs-button @click="deleteDialog=false" transparent dark block>
                    Cancel
                  </vs-button>
                </div>
              </footer>
            </div>
          </template>
        </vs-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: function () {
    return {
      deleteDialog: false
    }
  },
  methods: {
    editPart: function () {
      this.$emit('editPart', this.item)
    },
    deletePart: function () {
      this.$emit('deletePart', this.item)
      this.deleteDialog = false
    }
  }
}
</script>

<style scoped>
.part-item-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
}
.part-item-actions{
  margin-right: 20px;
  display: flex;
}
.part-item-info{
  margin-left: 20px;
}
.part-item-info-name{
  font-weight: 600;
  font-size: 17px;
}
.part-item-info-manufacturer{
  font-size: 14px;
}

.part-item{
  width: 100%;
  position: relative;
  height: 70px;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.part-item:before{
  content: "";
  position: absolute;
  bottom:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;
  transition: all .25s ease;
  z-index: -1;
  opacity: 1;
  box-sizing: border-box;
      background: rgba(200,200,200,0);
}
.part-item:hover:before{
  background: rgba(200,200,200,.1);
}
.part-item-actions{
}

.part-item-thumbnail {
  height: 60px;
  width: 60px;
  margin-left: 5px;
  border-radius: 17.6px;
  overflow: hidden;
}
.part-item-thumbnail > img {
  height: 100%;
  width: 100%;
}

.delete-footer{
  display: flex;
}
</style>
