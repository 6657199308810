<template>
  <div class="watch-parts-container">
    <h2>Watch parts</h2>
    <vs-card-group class="parts-group">
      <vs-card type="2" class="full-width-text" @click="changeType('case')">
          <template #title>
          <h3>Cases</h3>
          </template>
          <template #img>
          <img src="../../assets/admin/parts/case.jpg" alt="">
          </template>
          <template #text>
          <div class="card-text-filler"></div>
          </template>
          <template #interactions>

          <vs-button class="btn-chat" shadow primary>
              <i class='bx bx-paint'></i>
              <span class="span">
              {{parts.case.length}}
              </span>
          </vs-button>
          </template>
      </vs-card>
      <vs-card type="2" class="full-width-text" @click="changeType('bezel')">
          <template #title>
          <h3>Bezels</h3>
          </template>
          <template #img>
          <img src="../../assets/admin/parts/bezel.jpg" alt="">
          </template>
          <template #text>
          <p></p>
          </template>
          <template #interactions>
          <vs-button class="btn-chat" shadow primary>
              <i class='bx bx-paint'></i>
              <span class="span">
              {{parts.bezel.length}}
              </span>
          </vs-button>
          </template>
      </vs-card>

      <vs-card type="2" class="full-width-text" @click="changeType('hands')">
          <template #title>
          <h3>Hands</h3>
          </template>
          <template #img>
          <img src="../../assets/admin/parts/hands.jpg" alt="">
          </template>
          <template #text>
          <p></p>
          </template>
          <template #interactions>
          <vs-button class="btn-chat" shadow primary>
              <i class='bx bx-paint'></i>
              <span class="span">
              {{parts.hands.length}}
              </span>
          </vs-button>
          </template>
      </vs-card>

      <vs-card type="2" class="full-width-text" @click="changeType('dial')">
          <template #title>
          <h3>Dials</h3>
          </template>
          <template #img>
          <img src="../../assets/admin/parts/dial.jpg" alt="">
          </template>
          <template #text>
          <p></p>
          </template>
          <template #interactions>
          <vs-button class="btn-chat" shadow primary>
              <i class='bx bx-paint'></i>
              <span class="span">
              {{parts.dial.length}}
              </span>
          </vs-button>
          </template>
      </vs-card>
    </vs-card-group>
    <h3>{{typeTitle}}</h3>
    <div class="parts-list">
      <single-part @editPart="editPart" @deletePart="deletePart" v-for="(item, index) in parts[selectedType]" v-bind:key="index" :item="item"></single-part>
      <div class="new-part-button" @click="addNewPart">
        <i class='bx bx-plus-circle bx-sm'></i> <span> Add new part</span>
      </div>
    </div>
  <part-edit-dialog @partUpdate="partUpdate" :partsSpecs="partsSpecs" :isNew="isNewEdited" ref="editDialog"></part-edit-dialog>
  </div>
</template>

<script>
import * as Api from '@/api'
import SinglePart from './SinglePart.vue'
import PartEditDialog from './PartEditDialog.vue'

export default {
  data: function () {
    return {
      selectedType: 'case',
      editDialog: {
        active: false,
        item: null
      },
      openedDeleteDialogs: {
        1: false
      },
      parts: {
        case: [],
        bezel: [],
        dial: [],
        hands: []
      },
      partsSpecs: {},
      isNewEdited: false
    }
  },
  components: {
    SinglePart, PartEditDialog
  },
  computed: {
    typeTitle: function () {
      switch (this.selectedType) {
        case 'case': return 'Cases'
        case 'bezel': return 'Bezels'
        case 'hands': return 'Hands'
        case 'dial': return 'Dials'
        default: return ''
      }
    }
  },
  methods: {
    changeType: function (type) {
      this.selectedType = type
    },
    editPart: function (item) {
      this.$refs.editDialog.showDialog({ ...item })
      this.isNewEdited = false
    },
    addNewPart: function () {
      this.$refs.editDialog.showDialog({
        Name: `New ${this.selectedType}`,
        Items: {},
        Type: this.selectedType,
        Manufacturer: 'Unknown',
        ID: ''
      })
      this.isNewEdited = true
    },
    deletePart: function (item) {
      Api.deleteItem(item.ID)
        .then(response => {
          if (response.status === 'ok') {
            Api.getSellerParts()
              .then(parts => {
                this.parts = {
                  case: [],
                  bezel: [],
                  dial: [],
                  hands: []
                }
                parts.forEach(part => {
                  this.parts[part.Type].push(part)
                })
              })
          } else if (response.status === 'error' && response.message) {
            this.$vs.notification({
              flat: true,
              color: 'danger',
              position: 'bottom-right',
              title: 'Error deleting parts',
              text: response.message
            })
          }
        })
    },
    partUpdate: function () {
      Api.getSellerParts()
        .then(parts => {
          this.parts = {
            case: [],
            bezel: [],
            dial: [],
            hands: []
          }
          parts.forEach(part => {
            this.parts[part.Type].push(part)
          })
        })
    }
  },
  mounted: function () {
    Api.getSellerParts()
      .then(parts => {
        parts.forEach(part => {
          this.parts[part.Type].push(part)
        })
      })
    Api.getPartsSpecs()
      .then(partsSpecs => {
        this.partsSpecs = { ...partsSpecs }
      })
  },
  created: function () {
    document.title = 'Admin | Parts'
  }
}
</script>

<style>
.full-width-text .vs-card__text {
  width: 100%;
}

.parts-group .vs-card__group-cards::-webkit-scrollbar {
  width: 5px;
    height: 5px;
    display: block;
    background: var(--vs-theme-layout);
}
.parts-group .vs-card__group-cards::-webkit-scrollbar-thumb {
    background: rgb(100,100,100);
    border-radius: 5px;
}
</style>
<style scoped>
.parts-list{
  display: flex;
  flex-wrap: wrap;
}

.new-part-button{
  width: 100%;
  height: 50px;
  border-radius: 20px;
  border: dashed 2px rgb(150,150,150);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  background-color: rgba(200,200,200,0);
  transition: all .25s ease;
  cursor: pointer;
  margin-bottom: 20px;
}

.new-part-button:hover{
  background-color: rgba(200,200,200,.1);
}
.new-part-button > i {
  margin-right: 10px;

}

.watch-parts-container{
  width: 100%;
}
.card-text-filler{
  width: 100%;
}
.parts-group{
      margin-left: -50px;
    width: calc(100% + 100px);
    margin-right: -50px;
}
</style>
