<template>
  <vs-dialog prevent-close v-model="active" not-close>
    <template #header>
      <h4 class="part-edit-dialog-title">
        <span v-if="!isNew">Edit</span> <span v-else>Add new</span> {{item.Type}} part
      </h4>
    </template>

    <div class="part-edit-dialog-content">
      <div class="part-edit-dialog-inputs">
        <vs-input class="part-edit-dialog-input" block
          label-placeholder="Name" v-model="item.Name"
        />
        <vs-input class="part-edit-dialog-input" block :disabled="!isNew"
          label-placeholder="Manufacturer" v-model="item.Manufacturer"
        />
      </div>
      <div class="part-edit-dialog-items">
        <div class="part-edit-dialog-item" v-for="(i, index) in availableItems" v-bind:key="index">
          <div class="part-edit-dialog-item-image" :class="{thumbnail: i =='thumbnail'}">
            <input type="file" :id="`image-upload-${i}`" hidden accept=".png" v-on:change="imageUploadChange($event, i)"/>
              <label :for="`image-upload-${i}`" class="part-edit-dialog-item-image-label">
                <div class="part-edit-dialog-item-image-remove" v-if="item.Items[i]" v-on:click="removeItemImage($event, i)">
                  <i class='bx bx-x' ></i>
                </div>
                <img :src="getItemImage(i)" />
              </label>
          </div>
          <div class="part-edit-dialog-item-content">
            <div class="part-edit-dialog-item-name">{{getItemName(i)}} <span class="part-edit-dialog-item-required" v-if="isRequired(i)">required</span></div>
            <div class="part-edit-dialog-item-specs">{{getItemSpecs(i)}}</div>
            <div class="part-edit-dialog-item-template">
              <vs-button
              @click="downloadTemplate(i)"
              flat
              size="small"
            >
              Template
            </vs-button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <template #footer>
      <div class="part-edit-dialog-footer">
        <vs-button flat block @click="save">
          Save
        </vs-button>
        <vs-button flat danger block @click="cancel">
          Cancel
        </vs-button>

      </div>
    </template>
  </vs-dialog>
</template>

<script>
import * as Api from '@/api'

export default {
  props: ['partsSpecs', 'isNew'],
  data: function () {
    return {
      input1: '',
      input2: '',
      active: false,
      item: {
        Name: '',
        Items: {},
        Type: 'dial',
        Manufacturer: '',
        ID: ''
      }
    }
  },
  computed: {
    availableItems: function () {
      switch (this.item.Type) {
        case 'case': return ['thumbnail', 'case']
        case 'bezel': return ['thumbnail', 'bezel']
        case 'dial': return ['thumbnail', 'dial', 'dialNight']
        case 'hands': return ['thumbnail', 'handHour', 'handMinute', 'handSecond', 'handHourNight', 'handMinuteNight', 'handSecondNight']
        default: return ['thumbnail']
      }
    }
  },
  methods: {
    getItemImage: function (itemName) {
      return this.item.Items[itemName] ? (this.item.Items[itemName].indexOf('data:image/') === 0 ? this.item.Items[itemName] : '/assets/' + this.item.Items[itemName]) : '/assets/upload.png'
    },
    isRequired: function (item) {
      const specs = this.partsSpecs[item]
      if (specs) {
        return specs.Required
      }
      return false
    },
    getItemName: function (item) {
      const specs = this.partsSpecs[item]
      if (specs) {
        return specs.Name
      }
      return 'Need name for ' + item
    },
    getItemSpecs: function (item) {
      const specs = this.partsSpecs[item]
      if (specs) {
        return specs.Spec
      }
      return 'Need specs for ' + item
    },
    getItemSize: function (item) {
      const specs = this.partsSpecs[item]
      if (specs) {
        return specs.Size
      }
      return 'Need size for ' + item
    },
    imageUploadChange: function (event, i) {
      var reader = new FileReader()

      const specsSize = this.getItemSize(i)

      // Read the contents of Image File.
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        // Initiate the JavaScript Image object.
        var image = new Image()

        // Set the Base64 string return from FileReader as source.
        image.src = e.target.result

        // Validate the File Height and Width.
        image.onload = () => {
          if (image.height === specsSize.height && image.width === specsSize.width) {
            this.item.Items[i] = e.target.result
            this.item = { ...this.item }
          } else {
            this.$vs.notification({
              flat: true,
              color: 'danger',
              position: 'bottom-right',
              title: 'Incorrect size',
              text: `Graphic you have selected are incorrect size, it should be ${specsSize.width}x${specsSize.height}.`
            })
          }
        }
      }
    },
    showDialog: function (item) {
      this.active = true
      this.item = item
    },
    cancel: function () {
      this.active = false
    },
    removeItemImage: function (event, item) {
      this.item.Items[item] = null
      this.item = { ...this.item }
      event.preventDefault()
    },
    save: function () {
      const loading = this.$vs.loading({
        type: 'corners',
        text: 'Loading...'
      })
      if (!this.item.Name) {
        this.$vs.notification({
          flat: true,
          color: 'danger',
          position: 'bottom-right',
          title: 'Missing name',
          text: 'Name field is required for this part.'
        })
        loading.close()
        return
      }
      if (!this.item.Manufacturer) {
        this.$vs.notification({
          flat: true,
          color: 'danger',
          position: 'bottom-right',
          title: 'Missing manufacturer',
          text: 'Manufacturer field is required for this part.'
        })
        loading.close()
        return
      }

      const requiredItems = this.availableItems.every(i => {
        return this.partsSpecs[i] && (!this.partsSpecs[i].Required || (!!this.item.Items[i]))
      })
      if (!requiredItems) {
        this.$vs.notification({
          flat: true,
          color: 'danger',
          position: 'bottom-right',
          title: 'Missing images',
          text: 'Not all required images are selected.'
        })
        loading.close()
        return
      }

      Api.saveItem(this.item)
        .then(() => {
          loading.close()
          this.active = false
          this.$emit('partUpdate')
        })
    },
    downloadTemplate: function (type) {
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', `${type}-template.png`)
      link.href = `/assets/templates/${type}.png`
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>

<style>

.part-edit-dialog-item-image-remove{
  position: absolute;
  background-color: rgb(var( --vs-danger));

  display: flex;
  justify-content: center;
  align-items: center;
  height:25px;
  width:25px;
  border-radius: 10%;
  color: white;
  top: -5px;
  right: -5px;
  transform: scale(1);
  transition: all  0.25s ease;
}
.part-edit-dialog-item-image-remove:hover{
  transform: scale(1.2);
}
.part-edit-dialog-item-required {
  color: rgb(var( --vs-danger));
  font-size: 11px;
}
.part-edit-dialog-content{
  min-width: 600px;
}
.part-edit-dialog-title{
  margin: 10px;
}
.part-edit-dialog-footer{
  display: flex;
}
.part-edit-dialog-content{
  display: flex;
  flex-direction: column;
}
.part-edit-dialog-inputs{
  display: flex;
}
.part-edit-dialog-input{
  margin: 5px;
  flex: 1 1 0;
}
.part-edit-dialog-item{
  margin-top: 20px;
  display: flex;
}
.part-edit-dialog-item-image{
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 10%;
  border: 2px dashed rgb(225, 225, 225);
  padding: 5px;
}
.part-edit-dialog-item-image.thumbnail {
  height: 100px;
  width: 100px
}
.part-edit-dialog-item-image img {
  height: 100%;
  width: 100%;
  border-radius: 10%;
  object-fit: contain;
}
.part-edit-dialog-item-content{
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.part-edit-dialog-item-name{
  font-weight: 600;
  font-size: 17px;
}
.part-edit-dialog-item-specs{
  color: rgb(100, 100, 100);
  font-size: 15px;
}
.part-edit-dialog-item-template{
  margin-left: -5px;
}
</style>
