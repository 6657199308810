<template>
  <div class="order">
    <div class="order-body" :class="{hidden}">
      <div class="order-header"  @click="hidden = !hidden">
        <div class="order-image">
          <i class='bx bx-receipt'></i>
        </div>
        <div class="order-content">
          <div class="order-content-left">
            <div class="order-name">{{order.Data.firstLastName}}</div>
            <div class="order-date">{{new Date(order.Timestamp).toLocaleString()}}</div>
          </div>
          <div class="order-content-right">
            <div class="order-email">{{order.Data.email}}</div>
            <div class="order-country">{{order.Data.country}}</div>
          </div>
        </div>
        <div class="order-actions">
           <vs-button
            @click="confirmClicked"
            flat
            success
            >
            <i class='bx bx-check'></i> <span class="button-icon-text"> Confirm</span>
          </vs-button>
          <vs-button
          @click="deleteClicked"
            flat
            danger
            >
            <i class='bx bx-trash' ></i> <span class="button-icon-text">Delete</span>
          </vs-button>
        </div>
      </div>
      <h5 class="parts-title">Parts</h5>
      <div class="parts">
        <div class="part" v-for="item, k in getOrderParts(order)" v-bind:key="k">
          <img :src="typeIcon(item.Type)" alt="" class="part-icon">
          <div class="thumbnail">
            <img :src="`/assets/${item.Items.thumbnail}`" />
          </div>
          <div class="part-name">{{item.Name}}<span class="part-manufacturer"> by {{item.Manufacturer}}</span></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['order'],
  data: function () {
    return {
      hidden: true
    }
  },
  methods: {
    getOrderParts: function (order) {
      return Object.keys(order.Data.parts).map(a => order.Data.parts[a])
    },
    typeName: function (t) {
      switch (t) {
        case 'case': return 'Case'
        case 'bezel': return 'Bezel'
        case 'hands': return 'Hands'
        case 'dial': return 'Dial'
        default: return ''
      }
    },
    typeIcon: function (t) {
      switch (t) {
        case 'case': return require('../../assets/admin/parts/caseVector.svg')
        case 'bezel': return require('../../assets/admin/parts/bezelVector.svg')
        case 'dial': return require('../../assets/admin/parts/dialVector.svg')
        case 'hands': return require('../../assets/admin/parts/handsVector.svg')
        default: return require('../../assets/admin/parts/caseVector.svg')
      }
    },
    confirmClicked: function (e) {
      e.stopPropagation()
    },
    deleteClicked: function (e) {
      e.stopPropagation()
    }
  }
}
</script>

<style scoped>
.order-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}
.order-content-left{
  margin-left: 10px;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-name{
  font-weight: 600;
}
.order-date{
  font-size: 14px;
}
.order-content{
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
}
.order-actions{
  display: flex;
  border-left: 2px solid rgba(0,0,0,0.05);
  padding-left: 5px;
}
.button-icon-text{
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 600;
  margin-left: 5px;
}
.order-header{
  height: 60px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,var(--vs-shadow-opacity));
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.25s ease;
  background-color: white;
  z-index: 2;
  margin: -20px -20px 0px -20px;
}
.order{
}
.order-header:hover{
  background-color: rgba(0,0,0,0.1);;
}
.order-image{
  background-color: rgb(36, 33, 69);
  height: 40px;
  width: 40px;
  margin-left: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
}
.order-body{
  background-color: white;
  overflow: hidden;
  transition: max-height 1s ease;
  height: fit-content;
  max-height: 250px;
  border-radius: 20px;
  z-index: 1;
  background-color: white;
  padding: 20px;
  margin-bottom: 10px;
}
.order-body.hidden {
  max-height: 20px;
}

.parts-title{
   margin-top:20px;
  margin-bottom: 5px;
}

.part-icon{
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 50px;
  width: 50px;
  z-index: 0;
  opacity: 0.2;
}
.part-manufacturer{
  font-weight: 400;
  font-size: 14px;
}
.part-name{
  margin-left: 10px;
  font-weight: 600;
  font-size: 17px;
}
.parts{
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.part{
  position: relative;
  height: 60px;
  width: calc(50% - 10px);
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  display: flex;
  margin: 5px;
  align-items: center;
  transition: background-color 0.25s ease;
}
.part:hover{
  background-color: rgba(0, 0, 0, 0.05);
}
.thumbnail{
  height: 40px;
  width: 40px;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 10px;
}
</style>
