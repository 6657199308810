<template>
  <div class="home-container">
    <div class="hero-section">
      <div class="content">
        <div class="content-wrapper">
          <div class="content-header">
            <img src="../assets/icon.svg" alt="Combinative" />
            <div class="title">COMBINATIVE</div>
          </div>
          <div class="content-body">
            <div class="main-title">
              <h1>Help your customers pick the perfect combination</h1>
              <h3 style="font-weight: 400">Picking parts for a new watch build is not an easy task and you never know how it will end up looking.
                Make buying watch parts easier and enjoyable for your customers with a simple and user-friendly interface. <br><span style="font-weight:600">And easy to manage for you too!</span></h3>
            </div>
            <div class="discover-button-container">
              <vs-button
                  class="discover-button"
                  size="xl"
                  color="#5DC5F1"
                   href="#configurator"
                >
                  <span>Discover</span>
                </vs-button>
            </div>
           <!-- <div class="main-register-text">
              Are you a parts seller or custom watch builder and want to know more how it works?
            </div>
            <div class="main-register">

              <vs-input placeholder="Email" class="email-input" :class="{sent: requestSent}" v-model="requestEmail">
                <template #message-danger>
                  {{requestError}}
                </template>
              </vs-input>
              <vs-button
                class="request-button"
                @click="sendRequest"
                flat
                :active="requestSent"
              >
                <span v-if="requestSent">Request successufly sent <i class='bx bx-check'></i></span>
                <span v-else>Send request</span>
              </vs-button>
            </div>-->
            <div class="main-login">
            Already using service? <a class="login-link" href="/admin/login">Login here.</a>
            </div>
          </div>
        </div>
      </div>
      <div class="show-case">
        <div class="show-case-image">
          <img class="show-case-current" alt="Watch example 1" :class="{visible: !showImage.nextVisible}" :src="require(`../assets/landing/${showImage.current}.png`)" />
          <img class="show-case-next" alt="Watch example 2" :class="{visible: showImage.nextVisible}" :src="require(`../assets/landing/${showImage.next}.png`)" />
        </div>
      </div>
    </div>
    <div class="configurator-section" id="configurator">
      <div class="configurator-showcase">
        <div class="configurator-showcase-items">
          <div class="configurator-showcase-item"  :class="{hover:configuratorShowcaseActive== 1}"
            @mouseover="configuratorShowcaseActive = 1"
          >
            <i class='bx bxs-category-alt configurator-showcase-item-icon'></i>
            <div class="configurator-showcase-item-title">Many combinations</div>
            <div class="configurator-showcase-item-content">Pick and combine thousands of different possibilities and make it your own.</div>

          </div>
          <div class="configurator-showcase-item" :class="{hover:configuratorShowcaseActive== 2}"
            @mouseover="configuratorShowcaseActive = 2"
          >
            <i class='bx bxs-moon configurator-showcase-item-icon'></i>
            <div class="configurator-showcase-item-title">Different conditions</div>
            <div class="configurator-showcase-item-content">Preview configured product under different conditions like night and day.</div>
          </div>
          <div class="configurator-showcase-item"  :class="{hover:configuratorShowcaseActive== 3}"
            @mouseover="configuratorShowcaseActive = 3"
          >
            <i class='bx bx-git-repo-forked configurator-showcase-item-icon'></i>
            <div class="configurator-showcase-item-title">Conditional configuration</div>
            <div class="configurator-showcase-item-content">Pick parts that actually go together and avoid unnecessary purchases and returns.</div>
          </div>
        </div>
        <div class="configurator-showcase-items-content-mobile">
          <div class="configurator-showcase-item-content-mobile" :class="{hover:configuratorShowcaseActive== 1}">
            <div class="configurator-showcase-item-title">Many combinations</div>
            <div class="configurator-showcase-item-content">Pick and combine thousands of different possibilities and make it your own.</div>
          </div>
          <div class="configurator-showcase-item-content-mobile" :class="{hover:configuratorShowcaseActive== 2}">
            <div class="configurator-showcase-item-title">Different conditions</div>
            <div class="configurator-showcase-item-content">Preview configured product under different conditions like night and day.</div>
          </div>
          <div class="configurator-showcase-item-content-mobile" :class="{hover:configuratorShowcaseActive== 3}">
            <div class="configurator-showcase-item-title">Conditional configuration</div>
            <div class="configurator-showcase-item-content">Pick parts that actually go together and avoid unnecessary purchases and returns.</div>
          </div>
        </div>
        <div class="configurator-showcase-items-mobile">
          <div class="configurator-showcase-item-mobile"  :class="{hover:configuratorShowcaseActive== 1}"
            @mouseover="configuratorShowcaseActive = 1"
          >
            <i class='bx bxs-category-alt configurator-showcase-item-icon'></i>
          </div>
          <div class="configurator-showcase-item-mobile"  :class="{hover:configuratorShowcaseActive== 2}"
            @mouseover="configuratorShowcaseActive = 2"
          >
            <i class='bx bxs-moon configurator-showcase-item-icon'></i>
          </div>
          <div class="configurator-showcase-item-mobile"  :class="{hover:configuratorShowcaseActive== 3}"
            @mouseover="configuratorShowcaseActive = 3"
          >
            <i class='bx bx-git-repo-forked configurator-showcase-item-icon'></i>
          </div>
        </div>

        <div class="configurator-phone">
          <img class="configurator-phone-image" src="../assets/landing/configurator1.jpg" alt="Configurator preview">
          <img class="configurator-phone-alt-image" alt="Configurator preview night" :class="{visible: configuratorShowcaseActive== 3}" src="../assets/landing/configurator3.jpg">
          <img class="configurator-phone-alt-image" alt="Configurator preview combinations" :class="{visible: configuratorShowcaseActive== 2}" src="../assets/landing/configurator2.jpg">
        </div>
      </div>
      <div class="configurator-content">
        <div class="configurator-content-header">
          <div @click="scrollTo('')" class="configurator-content-header-title">COMBINATIVE</div>
          <img @click="scrollTo('')" src="../assets/icon.svg" alt="Combinative" />
        </div>
        <div class="configurator-content-body">
          <h2 class="configurator-title">The user needs to know, what he is buying!</h2>
          <h3 class="configurator-subtitle">There is almost always a doubt about how the result will end up when buying parts or requesting a mod.  <br>
            With our service, you can not only give your customers possibility to preview the final result, but also give them complete control of combining thousands of possible combinations and finding <span style="font-weight:600">the perfect one</span>. </h3>
          <div class="discover-button-container">
            <vs-button
                class="discover-button"
                size="xl"
                color="#FD4747"
                  href="#admin"
              >
                <span>How it works</span>
              </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-section" id="admin">
      <div class="admin-content">
        <div class="admin-content-header">
          <img @click="scrollTo('')" src="../assets/icon.svg" alt="Combinative" />
          <div @click="scrollTo('')" class="admin-content-header-title">COMBINATIVE</div>
        </div>
        <div class="admin-content-body">
          <h2 class="admin-title">Made simple even for a seller!</h2>
          <h3 class="admin-subtitle"> If the product is simple and intuitive for the end-user, it should also be for you! <br>
            Our dashboard offers:
            <ul>
              <li>Overview of all orders/inquiries that customers completed on the configurator.</li>
              <li>Simple management of adding and removing parts from configurator with just a few clicks</li>
              <li>In-depth analytics to see what customers are doing on the configurator, which parts are they picking, and complete funnel to analyze how the product is performing</li>
            </ul>
            With all that you can offer your customers great possibility of customization with <span style="font-weight:600">almost no effort</span> from your side. </h3>
          <div class="discover-button-container">
            <vs-button
                class="discover-button"
                size="xl"
                color="#FFC423"
                  href="#signup"
              >
                <span>Where to start</span>
              </vs-button>
          </div>
        </div>
      </div>
      <div class="admin-showcase">
        <div class="admin-tablet">
          <img class="admin-tablet-image-main" :class="{visible:adminShowcaseActive == 1}" src="../assets/landing/admin1.jpg">
          <img class="admin-tablet-image" :class="{visible:adminShowcaseActive == 2}" src="../assets/landing/admin2.jpg">
          <img class="admin-tablet-image" :class="{visible:adminShowcaseActive == 3}" src="../assets/landing/admin3.jpg">
          <img class="admin-tablet-image" :class="{visible:adminShowcaseActive == 4}" src="../assets/landing/admin4.jpg">
        </div>
        <div class="admin-tablet-toggles">
          <div class="admin-tablet-toggle" :class="{selected: adminShowcaseActive == 1 }" @click="adminShowcaseSkip = true; adminShowcaseActive = 1"></div>
          <div class="admin-tablet-toggle" :class="{selected: adminShowcaseActive == 2 }" @click="adminShowcaseSkip = true; adminShowcaseActive = 2"></div>
          <div class="admin-tablet-toggle" :class="{selected: adminShowcaseActive == 3 }" @click="adminShowcaseSkip = true; adminShowcaseActive = 3"></div>
          <div class="admin-tablet-toggle" :class="{selected: adminShowcaseActive == 4 }" @click="adminShowcaseSkip = true; adminShowcaseActive = 4"></div>
        </div>
      </div>
    </div>
    <div class="signup-section" id="signup">
      <div class="signup-header">
        <div @click="scrollTo('')" class="signup-header-title">COMBINATIVE</div>
        <img @click="scrollTo('')" src="../assets/icon.svg" alt="Combinative" />
      </div>
      <div class="signup-body">
        <h1 class="signup-title">Are you ready to make your upgrade your business?</h1>
        <h3 class="signup-subtitle">Interested or still considering it? Give us your email and let's  talk!</h3>
        <div class="signup-input">
          <vs-input placeholder="Email" class="signup-email-input" :class="{sent: requestSent}" v-model="requestEmail">
            <template #message-danger>
              {{requestError}}
            </template>
          </vs-input>
          <vs-button
            class="request-button"
            @click="sendRequest"

            :active="requestSent"
            color="#FD4747"
          >
            <span v-if="requestSent">We have got your email! <i class='bx bx-check'></i></span>
            <span v-else>Let's talk</span>
          </vs-button>
        </div>
      </div>
      <div class="signup-footer">
        <div class="copyrights">© 2021 Combinative. All rights reserved</div>
        <div class="terms-privacy">
          <div class="terms" @click="$refs.termsDialog.show()">Terms</div>
          <div class="privacy" @click="$refs.privacyDialog.show()">Privacy</div>
          <terms ref="termsDialog" />
          <privacy ref="privacyDialog" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as Utils from '@/utils'
import * as Api from '@/api'
import Terms from '../components/home/Terms.vue'
import Privacy from '../components/home/Privacy.vue'

export default {
  name: 'Home',
  data: function () {
    return {
      showImage: {
        current: 1,
        next: 2,
        nextVisible: false
      },
      configuratorShowcaseActive: 1,
      adminShowcaseActive: 1,
      adminShowcaseSkip: false,
      requestEmail: '',
      requestSent: false,
      requestError: '',
      termsActive: false
    }
  },
  components: {
    Terms,
    Privacy
  },
  methods: {
    sendRequest: function () {
      this.requestError = ''
      if (!this.requestEmail) {
        this.requestError = 'Required'
        return
      }
      if (!Utils.validateEmail(this.requestEmail)) {
        this.requestError = 'Wrong email'
        return
      }
      this.requestSent = true
      Api.trackEvent({ id: 'admin-request-sent', data: this.requestEmail })
    },
    scrollTo (hash) {
      location.hash = '#blank'
      setTimeout(() => {
        location.hash = '#' + hash
      })
    }
  },
  mounted: function () {
    setInterval(() => {
      if (this.showImage.nextVisible) {
        this.showImage.current = this.showImage.next + 1
        if (this.showImage.current === 5) {
          this.showImage.current = 1
        }
      } else {
        this.showImage.next = this.showImage.current + 1
        if (this.showImage.next === 5) {
          this.showImage.next = 1
        }
      }
      this.showImage.nextVisible = !this.showImage.nextVisible
    }, 5000)

    setInterval(() => {
      if (this.adminShowcaseSkip) {
        this.adminShowcaseSkip = false
      } else {
        this.adminShowcaseActive++
        if (this.adminShowcaseActive === 5) {
          this.adminShowcaseActive = 1
        }
      }
    }, 10000)
  },
  created: function () {
    document.title = 'Help your customers pick the perfect combination | Combinative'
  }
}
</script>
<style>
  .signup-email-input input{
    background-color: rgba(0,0,0, 0.9);
    color: white;
    width: 100%;
  }
  .signup-email-input input:focus{
    background-color: rgba(0,0,0, 0.9);
  }
</style>
<style scoped>
.configurator-phone-wrapper{
  height: 100%;
}

.signup-footer{
  display: flex;
  justify-content: space-between;
}
.terms-privacy{
  display: flex;
  cursor: pointer;
}
.terms{
  margin-right: 10px;
}
.terms:hover{
  font-weight: 600;
}
.privacy:hover{
  font-weight: 600;
}
.signup-section{
  height:100vh;
  background-color: #1D253B;
  display: flex;
  color: rgba(220,220,220,1);
  flex-direction: column;
  padding: 20px 50px;
  box-sizing: border-box;
}

.signup-header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.signup-header-title{
  margin-right: 20px;
  font-size: 40px;
  font-weight: 600;
  color:white;
  cursor: pointer;

}
.signup-header > img {
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  height: 40px;
  width: 40px;
  cursor: pointer;

}
.signup-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: center;
}

.signup-title{
  margin-top:0px;
  margin-bottom: 0px;
}
.signup-subtitle{
  font-weight: 400;
  color: rgb(220, 220, 220);
}
.signup-input{
  margin-top: 20px;
  display: flex;
}
.signup-email-input{
  width: 250px;
  transition: width 0.5s ease;
  margin-right: 10px;
  color: white;
}

.admin-section{
  height: 100vh;
  display: flex;
}
.admin-content{
  flex: 1 0 0;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px;
}
.admin-content-header{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.admin-content-header-title{
  margin-left: 20px;
  font-size: 40px;
  font-weight: 600;
  color:black;
  cursor: pointer;

}
.admin-content-header > img {
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  height: 40px;
  width: 40px;
  cursor: pointer;

}
.admin-content-body{
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-title{
  margin-top:0px;
  margin-bottom: 0px;
}
.admin-subtitle{
  font-weight: 400;
  color: #2c3e50;
}

.admin-showcase{
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
  flex-direction: column;
  flex: 1 0 0;
}
.admin-tablet{
  position: relative;
}
.admin-tablet-button{
  position: absolute;
  top: -4px;
  right: 50px;
  height: 4px;
  border-radius: 2px;
  width: 40px;
  background-color: rgb(150, 150, 150);
}
.admin-tablet-camera{
  position: absolute;
  top: 4px;
  left: calc(50% - 6px);
  background-color: black;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid rgb(100, 100, 100);
  box-sizing: border-box;
}
.admin-tablet>img{
  border-radius: 20px;
}
.admin-tablet-image-main{
  width: 100%;
}

.admin-tablet-image{
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: opacity 1s ease;
  width: 100%;
}

.admin-tablet-image.visible{
  opacity: 1;
}
.admin-tablet-toggles{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 20px;
  background-color: #1D253B;
  padding: 10px;
  border-radius: 20px;
}
.admin-tablet-toggle{
  height: 15px;
  width: 15px;
  border: 3px solid white;
  border-radius: 50%;
  margin:10px;
  box-sizing: border-box;
  transition: height 0.5s ease, width 0.5s ease;
  cursor: pointer;
}
.admin-tablet-toggle.selected{
  height: 15px;
  width: 15px;
  background-color: white;
}

.configurator-section{
  height:100vh;
  background-color: #1D253B;
  display: flex;
  color: rgba(220,220,220,1);
}
.configurator-showcase{
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  display: flex;
  padding: 20px;
}
.configurator-phone{
  position: relative;
  height: 100%;
  max-height: 827px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.configurator-phone-image{
  height: 100%;
}
.configurator-phone-alt-image{
  position: absolute;
  left:0px;
  top: 0px;
  opacity: 0;
  transition: opacity 0.5s ease;
  height: 100%;
}
.configurator-phone-alt-image.visible{
  opacity: 1;
}
.configurator-showcase-items{
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  max-height: 827px;
}
.configurator-showcase-item {
  color:white;
  padding: 20px;
  border-radius: 20px;
  height: 100px;
  width: 200px;
  background-color: rgba(255,255,255,0.05);
  transition: background-color 0.25s ease;
  text-align: right;
  cursor: pointer;
}

.configurator-showcase-item-icon{
  font-size: 25px;
}
.configurator-showcase-item-title{
  font-weight: 600;
}
.configurator-showcase-item-content{
  font-size: 13px;
}
.configurator-showcase-item.hover{
  background-color: rgba(255,255,255,1);
  color:black;
}

.configurator-content{
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px;
  flex: 1 0 0;
}
.configurator-content-header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.configurator-content-header-title{
  margin-right: 20px;
  font-size: 40px;
  font-weight: 600;
  color:white;
  cursor: pointer;

}
.configurator-content-header > img {
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  height: 40px;
  width: 40px;
  cursor: pointer;

}
.configurator-content-body{
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.configurator-title{
  margin-top:0px;
  margin-bottom: 0px;
}
.configurator-subtitle{
  font-weight: 400;
  color: rgb(220, 220, 220);
}
.discover-button-container{
  width: 100%;
}
.content-header{
  display: flex;
  align-items: center;
}
.content-header > img {
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  height: 70px;
  width: 70px;
  cursor: pointer;
}
.title{
  margin-left: 20px;
  font-size: 70px;
  font-weight: 600;
  color:black;
  cursor: pointer;
}
.request-button{
  margin: 0px;
  height: 32px;
}
.discover-button{
  margin: 0px;
}
.email-input{
  width: 200px;
  transition: width 0.5s ease;
  margin-right: 10px;
}
.sent{
  width: 0px;
  overflow: hidden;
  margin-right: 0px;
}
.login-link{
  text-decoration:underline;
  margin-left: 10px;
  color: black;
  font-style: normal;
}
.main-login{
  display: flex;
  max-width: 750px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  font-style: italic;
}
.main-register-text{
  display: flex;
  max-width: 750px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  font-style: italic;
}
.main-register{
  display: flex;
  max-width: 750px;
  width: 100%;
}
.show-case-current{
  image-rendering: auto;
  opacity: 0;
  transition: opacity 1s ease;
  filter: drop-shadow(12px 12px 10px rgba(0,0,0,1));
}
.show-case-current.visible{
  opacity: 1;
}
.show-case-next{
  image-rendering: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: opacity 1s ease;
  filter: drop-shadow(12px 12px 10px rgba(0,0,0,1));
}
.show-case-next.visible{
  opacity: 1;
}
.show-case-image{
  position: relative;
  height: 300px;
  width: 300px;
}
.home-container{

}
.hero-section{
  display: flex;
  height: 100vh;
  justify-content: space-around;
}
.content{
  position: relative;
  padding: 20px;
  flex: 2 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
}
.content-wrapper{
  position: relative;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;

}
.content-body{
  flex: 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main-title{
  max-width: 750px;
}
.show-case{
  min-width: 350px;
  flex: 1 1 0px;
  max-width: 750px;
  background-image: url(../assets/bg_small.jpg);
  display:flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 30px 10px rgba(0,0,0,0.9) inset;
  position: relative;
  background-position: center;
  image-rendering: pixelated;
  overflow: hidden;
}

.configurator-showcase-items-content-mobile{
  display: none;
}
.configurator-showcase-items-mobile{
  display: none;
}
@media only screen and (max-width: 1100px) {
  .configurator-section{
    height: initial;
    flex-wrap: wrap-reverse;
  }
  .configurator-content{
    padding: 20px;
  }
  .configurator-showcase{
    flex-wrap: wrap-reverse;
    min-width: 250px;
  }
  .configurator-phone{
    width: 60%;
    height: initial;
  }
  .configurator-phone-image{
    width: 100%;
    height: initial;
  }
  .configurator-showcase-items{
    display: none;
  }
  .configurator-showcase-items-mobile{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .configurator-showcase-item-mobile{
    padding: 10px;
    border-radius: 25%;
  }
  .configurator-showcase-item-mobile.hover{
    background-color: rgba(255,255,255,1);
    color:black;
  }
  .configurator-showcase-items-content-mobile{
    display: block;
  }
  .configurator-showcase-item-content-mobile{
    display: none;
    margin-top: 10px;
  }
  .configurator-showcase-item-content-mobile.hover{
    display: flex;
    flex-direction: column;
  }
  .configurator-showcase-item-title{
    text-align: center;
  }
  .configurator-showcase-item-content{
    text-align: center;
  }
}
@media only screen and (max-width: 950px) {
  .title{
    font-size: 50px;
    margin-left: 10px;
  }
  .content-header > img {
    width: 50px;
    height: 50px;
  }
  .admin-section{
    flex-wrap: wrap;
    height: initial;
  }
  .admin-showcase{
    min-width: 400px;
  }
  .hero-section{
    min-height: 100vh;
    height: auto;
  }

}
@media only screen and (max-width: 750px) {
  .hero-section{
    height: initial;
    flex-wrap: wrap;
    width: 100vw;
  }
  .content{
    min-width: 350px;
  }
  .discover-button{
    width: 100%;
  }
  .title{
    font-size: 35px;
    margin-left: 10px;
  }
  .content-header > img {
    width: 35px;
    height: 35px;
  }
  .configurator-content-header{
    margin-bottom: 20px;
  }

  .admin-content{
    padding: 20px;
  }
  .signup-section{
    padding: 20px;
  }
  .signup-input{
    flex-direction: column;
  }
  .request-button{
    width: 250px;
    margin-top: 10px;
    align-self: center;
  }
  .signup-footer{
    flex-direction: column;
    text-align: center;
  }
  .terms-privacy{
    margin-top: 5px;
    justify-content: center;
  }

}
@media only screen and (max-width: 400px) {
  .admin-showcase{
    min-width: min-content
  }
  .content{
    min-width: min-content
  }
  .show-case{
  }
  .configurator-content-header-title{
    font-size: 30px;
  }
  .configurator-content-header > img{
    height: 30px;
    width: 30px;
  }
  .admin-content-header-title{
    font-size: 30px;
  }
  .admin-content-header > img {
    height: 30px;
    width: 30px;
  }
  .signup-header-title{
    font-size: 30px;
  }
  .signup-header > img {
    height: 30px;
    width: 30px;
  }
}
</style>
