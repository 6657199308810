import Axios from 'axios'

export function trackEvent (data) {
  return new Promise((resolve, reject) => {
    Axios.post('/api/event', data, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function getParts (seller) {
  return new Promise((resolve, reject) => {
    Axios.post('/api/parts', { seller }, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function getUser () {
  return new Promise((resolve, reject) => {
    Axios.get('/api/user', { timeout: 30000 })
      .then(response => {
        if (response.status === 401) {
          reject({ type: 'unauthorized' })
        } else {
          resolve(response.data)
        }
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function loginUser (email, password) {
  return new Promise((resolve, reject) => {
    Axios.post('/api/user', { email, password }, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getSellerParts () {
  return new Promise((resolve, reject) => {
    Axios.post('/api/seller/parts', {}, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function getPartsSpecs () {
  return new Promise((resolve, reject) => {
    Axios.get('/api/parts/specs', { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function saveItem (item) {
  return new Promise((resolve, reject) => {
    Axios.post('/api/part/update', { item }, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function deleteItem (ID) {
  return new Promise((resolve, reject) => {
    Axios.post('/api/part/delete', { ID }, { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export function getOrders () {
  return new Promise((resolve, reject) => {
    Axios.get('/api/orders', { timeout: 30000 })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}
