<template>
  <div class="container">
    <div class="login-form">
      <h1>Hey there!</h1>
      <h4>Welcome back, please login to continue to configuration pages.<br> If you are here for the first time, contact us on zan@pevec.one.</h4>
      <vs-input class="email-input"
        label-placeholder="email"
        v-model="form.email"
        @change="emailChange"
      >
      <template v-if="form.emailMissing" #message-danger>
          Required
      </template>
      </vs-input>
      <vs-input class="password-input"
      type="password"
        label-placeholder="password"
        v-model="form.password"
        @change="passwordChange"
      >
      <template v-if="form.passwordMissing" #message-danger>
          Required
      </template>
      </vs-input>
      <vs-button class="login-button"
        size="large"
        block
        flat
        @click="loginClicked"
        >
        Login
      </vs-button>
    </div>
  </div>
</template>

<script>
import * as Api from '@/api'

export default {
  data: function () {
    return {
      form: {
        email: '',
        emailMissing: false,
        password: '',
        passwordMissing: false
      }
    }
  },
  methods: {
    loginClicked: function () {
      let missingSomething = false
      if (!this.form.email) {
        this.form.emailMissing = true
        missingSomething = true
      }
      if (!this.form.password) {
        this.form.passwordMissing = true
        missingSomething = true
      }
      if (missingSomething) {
        return
      }
      const loading = this.$vs.loading({
        type: 'corners'
      })

      Api.loginUser(this.form.email, this.form.password)
        .then(response => {
          if (response.status === 'ok') {
            this.$router.replace('/admin')
          }
          loading.close()
        })
        .catch(err => {
          console.error(err)
          loading.close()
        })
    },
    passwordChange: function () {
      this.form.passwordMissing = false
    },
    emailChange: function () {
      this.form.emailMissing = false
    }
  }
}

</script>

<style>
.email-input .vs-input__message{
  text-align: left;
}

.password-input .vs-input__message{
  text-align: left;
}

</style>

<style scoped>
.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.login-form{
  flex: 1 0 0;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center  ;

}
h1{
  margin-bottom: 0px;;
}
.email-input {
  margin-top: 20px
}
.password-input {
  margin-top: 20px;
}
.login-button{
  margin-top: 40px;
  width: 200px;
      align-self: center;
      margin-bottom: 100px;
}
</style>
