<template>
  <div class="part">
    <div class="part-title">{{title}}</div>
    <div class="items">
    <div class="item-container"  v-for="(item, index) in items" v-bind:key="index" v-on:click="changePart(type, item)">
        <div class="item-wrap"  :class="{selected:selectedItem.ID == item.ID}">
          <div class="item" >
              <div class="item-image">
                <img class="item-thumbnail" :src="`/assets/${item.Items.thumbnail}`" />
              </div>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'items', 'selectedItem', 'type', 'mobile'],
  methods: {
    getImgUrl (pic) {
      return require('@/assets/' + pic)
    },
    changePart (type, selectedPart) {
      this.$emit('changePart', type, selectedPart)
    }
  }
}
</script>
<style scoped>

.part{

  display: flex;
  justify-content: stretch;
  position: relative;
}
.part-right-shadow-drop{
    height:150%;
    width:20px;
    position: absolute;
    top:0px;
    right:0px;
    background-repeat: repeat-y;
    background-position-x: 100%;
    z-index: 1;
    background-image: url('../../assets/gradient.png');
    pointer-events: none;
}
.part-left-shadow-drop{
    height:150%;
    width:20px;
    position: absolute;
    top:0px;
    left:38px;
    background-repeat: repeat-y;
    background-position-x: 100%;
    z-index: 1;
    background-image: url('../../assets/gradient.png');
    transform: rotate(180deg);
    pointer-events: none;
}
.part-tooltip{
  display: flex;
}
.part-title{
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  transform: rotate(180deg);
  cursor: default;
  font-weight: 600;
  user-select: none;
  margin-right: 20px;
  color: rgb(150, 150, 150);
  align-self: flex-end;
}
.items{
  display:flex;
  margin-top:10px;
  overflow-x: auto;

  padding-top: 20px;
  margin-top: -10px;

  padding-bottom: 20px;
  margin-bottom: -20px;
   scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */

}
.items::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.item-container{
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}
.item-container:last-child{
  padding-right: 20px;
}
.item{
  border-radius: 35%;
  width: 44px;
  height: 44px;
  transition: transform 250ms,  box-shadow 250ms;
  position: relative;
  background-color: white;
  padding: 2px;
}
.item-wrap{

  position: relative;
  height:52px;
  width: 52px;
  border-radius: 35%;
  z-index: -1;
  padding: 2px;
  box-sizing: border-box;
}
.item-wrap.selected{
  background-image: linear-gradient(40deg, #f99b4a 0%, #df376b 74%, #c52d91 74%) !important;
}
.item-image{
  border-radius: inherit;
   width: 100%;
      height: 100%;
  overflow: hidden;
}
.item:hover{
  transform: translateY(-3px);
      box-shadow: 0 10px 20px -10px rgba(var(--vs-color),1);

}
.item-thumbnail{
  height: 100%;
  width:100%;
  transition: transform 250ms;
}
.item-container:hover .item-thumbnail{
  transform: scale(1.1);
}

.item.selected{
}
</style>
