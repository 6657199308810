<template>
  <vs-dialog prevent-close v-model="orderDialog">
      <template #header>
        <div class="order-header">
          <h2 class="not-margin order-header-title">
            <span v-if="!orderData.done">You are almost there</span>
            <span v-else>You are done!</span>

          </h2>
          <h4 class="not-margin order-header-subtitle">
            <span v-if="!orderData.done">Parts for you <span style="font-weight:600">custom</span> watch are selected! Last thing that we need is are contact information so we can get in touch and get you closer to your dream watch.</span>
            <span v-else> Inquiry has been sent, now kick back and relax and we will contact you as soon possible with all the details about your dream watch.</span>
          </h4>
        </div>

      </template>
      <div class="order-form" v-show="!orderData.done">
        <div class="order-form-items">
          <vs-input block class="order-form-item"
            label-placeholder="First & last name"
            v-model="orderData.firstLastName"
            @change="orderRequiredMessage.firstLastName = false"
          >
          <template v-if="orderRequiredMessage.firstLastName" #message-danger>
            Required
          </template>
          </vs-input>
          <vs-input block class="order-form-item"
            label-placeholder="Email"
            v-model="orderData.email"
            @change="orderRequiredMessage.email = ''"
          >
          <template v-if="orderRequiredMessage.email" #message-danger>
            {{orderRequiredMessage.email}}
          </template>

          </vs-input>
          <vs-input block class="order-form-item"
            label-placeholder="Country"
            v-model="orderData.country"
            @change="orderRequiredMessage.country = false"
           >
          <template v-if="orderRequiredMessage.country" #message-danger>
            Required
          </template>
          </vs-input>
          <div class="toggle-order-form-item">
            <vs-switch class="toggle" v-model="orderData.build">
              <template #off>
                  No
              </template>
              <template #on>
                  Yes
              </template>
            </vs-switch>
            <h5>Do you want us to build watch for you</h5>
          </div>
          <div class="checkbox-order-form-item">
            <vs-checkbox v-model="orderData.terms" @change="orderRequiredMessage.terms = false" val="true">
              I agree with  <a class="terms-url" href="#">Terms and agreements</a>
              <div class="checkbox-order-form-item-required" v-if="orderRequiredMessage.terms" >
                Required
              </div>

            </vs-checkbox>
          </div>
        </div>
      </div>
      <template #footer  >
        <div class="footer-dialog" v-show="!orderData.done">
          <div class="footer-items">
            <vs-button flat block @click="orderSend">
              Send inquiry
            </vs-button>
          </div>
        </div>
      </template>
    </vs-dialog>
</template>

<script>
import * as Api from '@/api'
import * as Utils from '@/utils'

export default {
  props: ['selectedParts'],
  data: function () {
    return {
      orderDialog: false,
      orderData: {
        firstLastName: '',
        email: '',
        country: '',
        build: true,
        terms: false,
        done: false
      },
      orderRequiredMessage: {
        firstLastName: false,
        email: false,
        country: false,
        terms: false
      }
    }
  },
  methods: {
    showDialog () {
      this.orderDialog = true
      this.orderData = {
        firstLastName: '',
        email: '',
        country: '',
        build: true,
        done: false
      }
      this.orderRequiredMessage = {
        firstLastName: false,
        email: false,
        country: false,
        terms: false
      }
    },
    hideDialog () {
      this.orderDialog = false
    },
    orderSend () {
      let isError = false
      if (!this.orderData.firstLastName) {
        this.orderRequiredMessage.firstLastName = true
        isError = true
      }
      if (!this.orderData.email) {
        this.orderRequiredMessage.email = 'Required'
        isError = true
      } else {
        if (!Utils.validateEmail(this.orderData.email)) {
          this.orderRequiredMessage.email = 'Invalid email'
          isError = true
        }
      }

      if (!this.orderData.terms) {
        this.orderRequiredMessage.terms = true
        isError = true
      }

      if (isError) return

      const loading = this.$vs.loading({
        type: 'corners',
        text: 'Sending inquiry to our robots...'
      })
      Api.trackEvent({ id: 'builder-order-sent', data: { ...this.orderData, parts: this.selectedParts } })
        .then((response) => {
          if (response.status === 'ok') {
            setTimeout(() => {
              loading.close()
              this.orderData.done = true
              this.$emit('done')
            }, 3000)
          }
        })
      this.$gtag.event('builder-order-sent', { data: this.selectedParts })
    }
  },
  watch: {
    'orderData.build': function (newValue) {
      Api.trackEvent({ id: 'builder-order-build-change', data: newValue })
      this.$gtag.event('builder-order-build-change', { data: newValue })
    }
  }
}
</script>

<style scoped>
.checkbox-order-form-item-required{
  color: rgba(var(--vs-danger),1);
  position: absolute;
  bottom: -10px;
  left: -20px;
  font-size: 0.7rem;
}
.terms-url{
  margin-left: 5px;
}
.terms-url:link{
  color: rgba(var(--vs-primary),1);
  text-decoration: unset;
}
.terms-url:visited{
  color: rgba(var(--vs-primary),1);
  text-decoration: unset;

}
.checkbox-order-form-item{
  margin-top: 20px;
}
.order-form-item{
  margin-bottom:25px;
}
.toggle-order-form-item{
  display: flex;
  align-items: center;
}
.toggle-order-form-item>.toggle{
  width: 60px;
  margin-right: 10px;
}
.toggle-order-form-item>h5{
  margin-top: 10px;
  margin-bottom: 10px;
}
.footer-dialog{
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.footer-items{
  width: 320px;
}

.footer{
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding:10px;
  color: rgb(100, 100, 100);
  font-weight: 400;
  font-size: 15px;
}

.vs-dialog{
  transition: height 500ms;
}
.order-form{
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column;
  align-items: center;

}
.order-form-items{
  width: 320px;
  display: flex;
  flex-direction: column;
}

.order-header-title{
  text-align: center;
  font-weight: 500;
}
.order-header-subtitle{
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
  font-weight: 500;
  color:rgb(100, 100, 100);
}
</style>>
