<template>
  <div>
      <vs-sidebar
        v-show="!isLogin"
        absolute
        reduce
        v-model="active"
        open
        >
        <template #logo>
          <img src="../assets/icon.svg" />
        </template>
        <vs-sidebar-item id="home" to="/admin">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          Home
        </vs-sidebar-item>
        <vs-sidebar-item id="watch-parts" to="/admin/parts">
          <template #icon>
            <i class='bx bxs-watch' ></i>
          </template>
          Watch parts
        </vs-sidebar-item>
        <vs-sidebar-item id="analytics" to="/admin/analytics">
          <template #icon>
            <i class='bx bx-line-chart'></i>
          </template>
          Analytics
        </vs-sidebar-item>
        <template #footer>
          <vs-row justify="center">
            <vs-avatar badge-color="danger" badge-position="top-right" v-if="false">
              <i class='bx bx-bell' ></i>

              <template #badge>
                28
              </template>
            </vs-avatar>
            <div class="footer-separator"></div>

            <vs-tooltip shadow>
              <vs-avatar primary>
                <i class='bx bx-user' ></i>
              </vs-avatar>
              <template #tooltip>
                <div class="account-tooltip">
                  <div class="account-icon"><i class='bx bxs-user-circle' ></i></div>
                  <div class="account-data">
                    <div class="account-name">{{user.Name}}</div>
                    <div class="account-email">{{user.Email}}</div>

                  </div>

                </div>
              </template>
            </vs-tooltip>
          </vs-row>
        </template>
      </vs-sidebar>
      <div class="content">
          <router-view/>
      </div>
          <div class="footer">
        <a target="_blank" href="/" >Powered by <img src="../assets/icon.svg" /> <span style="font-weight:600">COMBINATIVE</span></a>
      </div>

  </div>
</template>

<script>
import * as Api from '@/api'

export default {
  data: function () {
    return {
      active: 'home',
      user: {},
      visible: true
    }
  },

  computed: {
    isLogin: function () {
      return this.$route.name === 'Admin-login'
    }
  },
  created: function () {
    if (this.$route.name === 'Admin-parts') {
      this.active = 'watch-parts'
    } else if (this.$route.name === 'Admin-analytics') {
      this.active = 'analytics'
    }
    Api.getUser()
      .then((response) => {
        if (response.status === 'unauthorized') {
          return this.$router.push('/admin/login')
        } else if (response.status === 'ok') {
          this.user = response.data
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

}
</script>

<style scoped>
.footer {
  height: 40px;
  width: 100%;
  align-self: flex-end;
  justify-content: flex-end;
  display: flex;
  padding-right: 10px;
  box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.02);

}
.footer img {
  margin-left: 5px;
  height: 20px;
  width: 20px;
}
.footer a{
  text-decoration: unset;
  color: #2c3e50;
    display: flex;
  align-items: center;
}

.account-tooltip{
  text-align: left;
  padding: 20px;
  display: flex;

}
.account-icon{
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.account-name{
  font-size: 19px;
  font-weight: 600;
}
.footer-separator {
    height: 5px;
    width: 100%;
}
.content {
    margin-left: 50px;
    padding: 50px;
    display: flex;
    box-sizing: border-box;
    height: calc( 100vh - 40px);
    background-color: rgba(0, 0, 0, 0.02);
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
}
</style>
