import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Builder from '../views/Builder.vue'

import Admin from '../views/Admin.vue'
import AdminWatchParts from '../components/admin/WatchParts'
import AdminLogin from '../components/admin/Login'
import AdminHome from '../components/admin/Home'
import AdminAnalytics from '../components/admin/Analytics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/builder/',
    name: 'Builder',
    component: Builder
  },
  {
    path: '/builder/:seller',
    name: 'Builder',
    component: Builder
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'Admin-home',
        component: AdminHome
      },
      {
        path: '/admin/parts',
        name: 'Admin-parts',
        component: AdminWatchParts
      },
      {
        path: '/admin/analytics',
        name: 'Admin-analytics',
        component: AdminAnalytics
      },
      {
        path: '/admin/login',
        name: 'Admin-login',
        component: AdminLogin
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
