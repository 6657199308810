import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' // Vuesax styles

import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.use(Vuesax, {
  // options here
})

Vue.use(VueGtag, {
  config: { id: 'G-6P02S2WVS3' }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
