<template>
  <div class="container">
    <div class="main-controls">
      <div class="controls">
        <div class="titles">
          <h2 class="controls-title">Configure your watch</h2>
          <h4 class="subtitle">Pick colors, parts and designs that match your life style and experience quality watches made <span style="font-weight:600">custom</span> just for you.</h4>
        </div>
        <div ref="partsList" class="parts-list">
          <part-container
            title="CASE"
            description="Case is main component holding and protecting all other parts. It is usually metalic but it can come in different materials and colors"
            :items="parts.case"
            :selectedItem="selectedParts.case"
            type="case"
            @changePart="changePart"
            :mobile="true"
          ></part-container>
          <part-container
            title="BEZEL"
            description="Bezel is top cover on case and it containes bazel insert, it can be rotate one way or both way"
            :items="parts.bezel"
            :selectedItem="selectedParts.bezel"
            type="bezel"
            @changePart="changePart"
            :mobile="true"
          ></part-container>
          <part-container
            title="DIAL"
            description="Dial is positioned on top of the movement and it is portion of wathc that display time"
            :items="parts.dial"
            :selectedItem="selectedParts.dial"
            type="dial"
            @changePart="changePart"
            :mobile="true"
          ></part-container>
          <part-container
            title="HANDS"
            description="Hands are the markers on the dial that indicate time. Most watches have at least three hands to show hours, minutes, and seconds"
            :items="parts.hands"
            :selectedItem="selectedParts.hands"
            type="hands"
            @changePart="changePart"
            :mobile="true"
          ></part-container>

          <!--<part-container
            title="HANDS"
            description="Hands are the markers on the dial that indicate time. Most watches have at least three hands to show hours, minutes, and seconds"
            :items="parts.hands"
            :selectedItem="selectedParts.hands"
            type="hands"
            @changePart="changePart"
            :mobile="true"
          ></part-container> -->
        </div>
        <div  class="bottom-controls">
          <vs-button
          class="continue-button"
          block
          flat
          @click="openOrderDialog"
          >
          Continue <i style="margin-left:5px" class='bx bx-right-arrow-alt'></i>
          </vs-button>
          <vs-switch dark v-model="nightMode">
            <template #circle>
                <i v-if="!nightMode" class='bx bxs-sun' ></i>
                <i v-else class='bx bxs-moon' ></i>
            </template>
        </vs-switch>
        </div>
      </div>
      </div>
    <order-dialog  @done="orderDone" :selectedParts="selectedParts" ref="orderDialog"></order-dialog>
  </div>
</template>

<script>
import PartContainer from './PartContainer.vue'
import OrderDialog from './OrderDialog.vue'

export default {
  components: { PartContainer, OrderDialog },
  props: ['parts', 'selectedParts', 'currentNightMode'],
  data: function () {
    return {
      nightMode: false,
      orderDialog: false,
      scrollTop: 0,
      orderData: {
        firstLastName: '',
        email: '',
        country: '',
        build: true,
        done: false
      }
    }
  },
  methods: {
    getImgUrl (pic) {
      return require('@/assets/' + pic)
    },
    changePart (type, selectedPart) {
      this.$emit('changePart', type, selectedPart)
    },
    openOrderDialog () {
      this.$refs.orderDialog.showDialog()
    },
    orderSend () {
      const loading = this.$vs.loading({
        type: 'corners',
        text: 'Sending inquiry to our robots...'
      })
      setTimeout(() => {
        loading.close()
        this.orderData.done = true
      }, 3000)
    },
    orderDone: function () {
      this.orderDialog = false
      this.$refs.orderDialog.hideDialog()
      this.$emit('done')
    }
  },
  mounted: function () {

  },
  watch: {
    nightMode: function (newValue) {
      this.$emit('changeNightMode', newValue)
    },
    currentNightMode: function (newValue) {
      this.nightMode = newValue
    }
  }
}
</script>
<style>

</style>
<style scoped>

.order-form-item{
  margin-bottom:25px;
}
.toggle-order-form-item{
  display: flex;
  align-items: center;
}
.toggle-order-form-item>.toggle{
  width: 60px;
  margin-right: 10px;
}
.toggle-order-form-item>h5{
  margin-top: 10px;
  margin-bottom: 10px;
}
.footer-dialog{
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.footer-items{
  width: 320px;
}

.footer{
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding:10px;
  color: rgb(100, 100, 100);
  font-weight: 400;
  font-size: 15px;
}

.vs-dialog{
  transition: height 500ms;
}
.order-form{
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column;
  align-items: center;

}
.order-form-items{
  width: 320px;
  display: flex;
  flex-direction: column;
}

.order-header-title{
  text-align: center;
  font-weight: 500;
}
.order-header-subtitle{
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
  font-weight: 500;
  color:rgb(100, 100, 100);
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 100vw;
}
.main-controls{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.controls{
  display: flex;
  flex-direction: column;
  height:100%;
  overflow: hidden;
  width: 100%;
}
.titles{
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.15);
}
.parts-list{
  flex: 1 1 0;
  overflow: auto;
  /* box-shadow: inset 0px 0px 9px 0px rgba(0,0,0,0.15);*/
  padding: 5px;
}
.controls-title{
  text-align: center;
  margin: 10px;

}
.subtitle{
  font-weight: 400;
  margin: 0 10px 20px 10px;
  text-align: center;
}

.bottom-controls{
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.15);
  padding: 5px 5px 5px 5px;
  display: flex;
}
.continue-button{
  margin: 0;
  margin-right: 10px;
}

</style>
